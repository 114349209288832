import '../Resources/Scss/Citylager.scss';
// @todo: check whether we can only import the modules we need
import * as bootstrap from 'bootstrap';
// import '../../../public/typo3conf/ext/mindshape_cookie_consent/Resources/Public/JavaScript/cookie_consent'
// import '@fancyapps/ui/dist/fancybox.umd'
import '../Resources/JavaScript/Citylager';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";
import "@fancyapps/ui/dist/carousel/carousel.css";

// @todo get rid of that and import what we need instead
window.bootstrap = bootstrap;
