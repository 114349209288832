export function navToggleDesktopMobile() {

  // let toggleWidth = 576;
  let toggleWidth = 992;

  const offcanvasRight = document.getElementById('offcanvasRight');
  // const desktopNavigation = document.querySelector("#desktop-navigation");
  const desktopNavigation = document.getElementById('desktop-navigation');

  const hamburgerMobile = document.getElementById('hamburger-mobile-nav');
  const hamburgerDesktop = document.getElementById('hamburger-desktop-nav');
  const bsOffcanvas = new bootstrap.Offcanvas('#offcanvasRight')

  let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  let showNavCurrent = 'mobile';
  if (vw >= toggleWidth) {
    showNavCurrent = 'desktop'
  }
  let showNavNew = showNavCurrent;

  let navIsOpen = false;
  navIsOpen = getNavStatus();

  // document.querySelector('#hamburger-mobile-nav').addEventListener('click', function (e) {
  hamburgerMobile.addEventListener('click', function (e) {

    if (offcanvasRight.classList.contains("show")) {
      this.classList.remove("is-opened-navi");
      document.body.classList.remove("overflow");
    } else {
      this.classList.add("is-opened-navi");
    }
  });

  function toggleNav(showNav) {
    navIsOpen = getNavStatus();
    if (navIsOpen === true) {
      if (showNav === 'desktop') {
        bsOffcanvas.hide();
        document.body.classList.add("overflow");
        document.querySelector("#desktop-navigation").classList.add("active");
        document.querySelector("#hamburger-mobile-nav").classList.remove("is-opened-navi");
        document.querySelector("#hamburger-desktop-nav").classList.add("is-opened-navi");
      } else {
        bsOffcanvas.show();
        document.querySelector("#desktop-navigation").classList.remove("active");
        document.querySelector("#hamburger-mobile-nav").classList.add("is-opened-navi");
        document.querySelector("#hamburger-desktop-nav").classList.remove("is-opened-navi");
      }
    }
  }

  /**
   *
   * @returns {boolean}
   */
  function getNavStatus() {
    navIsOpen = false;

    if (desktopNavigation.classList.contains("active")) {
      navIsOpen = true;
    }


    if (offcanvasRight.classList.contains("show")) {
      navIsOpen = true;
    }
    return navIsOpen;
  }

  window.addEventListener('resize', function (event) {
    vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    if (vw >= toggleWidth) {
      showNavNew = 'desktop';

      if (showNavCurrent === 'mobile') {
        showNavCurrent = 'desktop';
        toggleNav(showNavCurrent);
      }

    } else {
      showNavNew = 'mobile';
      if (showNavCurrent === 'desktop') {
        showNavCurrent = 'mobile';
        toggleNav(showNavCurrent);
      }
    }
  });

}
