import './Component/Typo3LegacyMailEncryption'
// import 'jquery'
// import 'jquery-migrate'
//
// import './Old/wow.min'
// import './Old/swiper'
// import './Old/splitting.min'
import { localStorageSVGs } from "./Component/SvgSpriteCache";
import { navToggleDesktopMobile } from "./Component/NavToggleDesktopMobile";
import { ready } from "./Utility/Ready";
// import {debounce} from "chart.js/helpers";
import {debounce} from "./Old/scripts";


// import { Fancybox } from "@fancyapps/ui";
// // import "@fancyapps/ui/dist/fancybox/fancybox.css";
//
// import {Carousel} from "@fancyapps/ui/dist/carousel/carousel.esm.js";
// // import "@fancyapps/ui/dist/carousel/carousel.css";
//
// import {Autoplay} from "@fancyapps/ui/dist/carousel/carousel.autoplay.esm.js";
// // import "@fancyapps/ui/dist/carousel/carousel.autoplay.css";


ready(function () {
  // console.log('Ready');
  navToggleDesktopMobile();
  localStorageSVGs();
  // initFancyCarousel();
});

// function initFancyCarousel() {
//   const container = document.getElementById("myCarousel");
//   const options = {
//     Autoplay: {
//       timeout: 1000,
//     },
//   };
//
//   new Carousel(container, options, { Autoplay });
//
//
// //   const myCarousel = new Carousel(document.querySelector(".carousel"), {
// //     // Options
// //   });
// }
