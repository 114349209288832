(function ($) {
  $(document).ready(function () {
    "use strict";

    // BACK BUTTON RELOAD
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
      }
    };

    // ICON CONTENT BLOCK
    $('.icon-content-block .content-block').mouseenter(function () {
      $('.selected').removeClass('selected').addClass('icon-content-block .content-block');
      $(this).removeClass('icon-content-block .content-block').addClass('selected');
    });


    // SPLITTING
    Splitting();


    // DATA BACKGROUND IMAGE
    // var pageSection = $(".swiper-slide");
    // pageSection.each(function (indx) {
    //   if ($(this).attr("data-background")) {
    //     $(this).css("background-image", "url(" + $(this).data("background") + ")");
    //   }
    // });


    // TREE MENU
    $('.site-navigation .inner ul li i').click(function () {
      $(this).parent().children('.site-navigation .inner ul li ul').slideToggle(300);
      return true;
    });


    // HAMBURGER MENU
    let scrollTopPosition = 0;
    $('#hamburger-desktop-nav').on('click', function (e) {

      let siteNavigation = $(".site-navigation");
      if (siteNavigation.hasClass("active")) {
        // $("body").toggleClass("overflow");
        $("body").removeClass("overflow");
        siteNavigation.removeClass("active");
        // // siteNavigation.css("transition-delay", "0.5s"); edit
        // siteNavigation.css("transition-delay", "0.2s");
        // // $(".site-navigation .layer").css("transition-delay", "0.3s"); edit
        // $(".site-navigation .layer").css("transition-delay", "0.1s");
        // $(".site-navigation .inner").css("transition-delay", "0s");
        //
        // // $(".content-container").removeClass('d-none');
        // // $(".content-container").css("transition", "opacity 3s");
        // // $(".content-container").css("opacity", "1");
        window.scrollTo(0,scrollTopPosition);
      } else {
        siteNavigation.addClass('active');
        // $("body").toggleClass("overflow");
        $("body").addClass("overflow");
        // $(".site-navigation.active").css("transition-delay", "0s");
        // // $(".site-navigation.active .layer").css("transition-delay", "0.2s"); edit
        // $(".site-navigation.active .layer").css("transition-delay", "0.1s");
        // // $(".site-navigation.active .inner").css("transition-delay", "0.7s"); edit
        // $(".site-navigation.active .inner").css("transition-delay", "0.2s");

        scrollTopPosition = document.documentElement.scrollTop; // || document.body.scrollTop
        // $(".content-container").css("transition", "opacity 3s");
        // $(".content-container").css("opacity", "0.01");
        // $(".content-container").addClass('d-none');
      }
      $(this).toggleClass("is-opened-navi");
    });



    // FOOTER HEIGHT CALCULATION
    // Wird für die Einblendung des Footers benötigt
    $('body').css({
      'margin-bottom': $('.footer').innerHeight()
    });

    // PAGE TRANSITION
    $('body a').on('click', function (e) {


      var target = $(this).attr('target');
      var fancybox = $(this).data('fancybox');
      var url = this.getAttribute("href");
      if (target != '_blank' && typeof fancybox == 'undefined' && url.indexOf('#') && url.indexOf('javascript:void(0);') < 0) {


        e.preventDefault();
        var url = this.getAttribute("href");

        if (url.indexOf('#' && 'javascript:void(0);') != -1) {
          var hash = url.substring(url.indexOf('#'));


          if ($('body ' + hash).length != 0) {
            $('.page-transition').removeClass("active");
            $(".hamburger").toggleClass("open");
            $("body").toggleClass("overflow");
            $(".navigation-menu").removeClass("active");
            $(".navigation-menu .inner ul").css("transition-delay", "0s");
            $(".navigation-menu .inner blockquote").css("transition-delay", "0s");
            $(".navigation-menu .bg-layers span").css("transition-delay", "0.3s");

            $('html, body').animate({
              scrollTop: $(hash).offset().top
            }, 1000);
          }
        } else {

          $('.page-transition').toggleClass("active");
          setTimeout(function () {
            window.location = url;
          }, 1000);
        }
      }
    });


  });
  // END JQUERY


  // SLIDER
   var mainslider = new Swiper('.gallery-top', {
     spaceBetween: 0,
     autoplay: {
       delay: 9500,
       disableOnInteraction: false,
     },
     navigation: {
       nextEl: '.swiper-button-next',
       prevEl: '.swiper-button-prev',
     },
     pagination: {
       el: '.swiper-pagination',
       type: 'progressbar',
     },
     loop: true,
     loopedSlides: 3,
     thumbs: {
       swiper: sliderthumbs
     }
   });


  // SLIDER THUMBS
  var sliderthumbs = new Swiper('.gallery-thumbs', {
    spaceBetween: 10,
    centeredSlides: true,
    slidesPerView: 3,
    touchRatio: 0.2,
    slideToClickedSlide: true,
    loop: true,
    loopedSlides: 3,
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 1
      },
      640: {
        slidesPerView: 1
      },
      320: {
        slidesPerView: 1
      }
    }
  });

  if ($(".gallery-top")[0]) {
    mainslider.controller.control = sliderthumbs;
    sliderthumbs.controller.control = mainslider;
  } else {

  }


  // OFFICE SLIDER
  new Swiper('.office-slider', {
    slidesPerView: '1',
    spaceBetween: 0,
    centeredSlides: true,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });


  // SLIDER
  // var carouselslider = new Swiper('.carousel-slider', {
  //   spaceBetween: 0,
  //   slidesPerView: 3,
  //   centeredSlides: true,
  //   autoplay: {
  //     delay: 9500,
  //     disableOnInteraction: false,
  //   },
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },
  //   pagination: {
  //     el: '.swiper-pagination',
  //     type: 'progressbar',
  //   },
  //   loop: true,
  //   breakpoints: {
  //     1024: {
  //       slidesPerView: 3
  //     },
  //     768: {
  //       slidesPerView: 2
  //     },
  //     640: {
  //       slidesPerView: 1
  //     },
  //     320: {
  //       slidesPerView: 1
  //     }
  //   }
  // });


  // TESTIMONIALS
  // new Swiper('.testimonials-slider', {
  //   slidesPerView: '1',
  //   spaceBetween: 0,
  //   centeredSlides: true,
  //   loop: true,
  //   pagination: {
  //     el: '.swiper-pagination',
  //     clickable: true,
  //   },
  // });


   // COUNTER
   // $(document).scroll(function () {
   //   $('.odometer').each(function () {
   //     var parent_section_postion = $(this).closest('section').position();
   //     var parent_section_top = parent_section_postion.top;
   //     if ($(document).scrollTop() > parent_section_top - 300) {
   //       if ($(this).data('status') == 'yes') {
   //         $(this).html($(this).data('count'));
   //         $(this).data('status', 'no');
   //       }
   //     }
   //   });
   // });


  // WOW ANIMATION
  wow = new WOW({
    animateClass: 'animated',
    offset: 50
  });
  wow.init();


   // PRELOADER
   $(window).load(function () {
     $("body").addClass("page-loaded");
   });


})(jQuery);
